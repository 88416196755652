@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";


//
// CTA-Element
//

.cta {
    //background-color: $blue;
    color: $white;
    opacity: 0;
}

.cta-text {
    padding: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(lg) {
        align-items: flex-start;
        text-align: left;
        padding: 1.75rem;
        min-height: 18.75rem;

        .cta-title {
            max-width: 88%;
        }
    }

    @include media-breakpoint-up(xl) {
        text-align: left;
        padding: 2rem;
        min-height: 21.25rem;

        .cta-title {
            &.h3 {
                font-size: 2.625rem;
            }
        }
    }
}

.cta-image {

    @include media-breakpoint-up(lg) {
        position: relative;
        height: 100%;

        .cta-img {
            @include image-object-cover();
        }
    }
}
